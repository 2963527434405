import httpClient from './httpClient';

const END_POINT = '/invoices';


const getAllInvoices = (params) => httpClient.get(END_POINT, { params });

// you can pass arguments to use as request parameters/data
const getInvoice = (id) => httpClient.get(END_POINT + '/' + id);
// maybe more than one..
const createInvoice = (invoice) => httpClient.post(END_POINT, { invoice });

const updateInvoice = (id, invoice) => httpClient.put(END_POINT + '/' + id, { invoice } );

const cancelInvoice = (id) => httpClient.post(END_POINT + '/' + id + '/cancel');

const lossInvoice = (id) => httpClient.post(END_POINT + '/' + id + '/loss');

const deleteInvoice = (id) => httpClient.delete(END_POINT + '/' + id);

const generateInvoice = (id) => httpClient.get(END_POINT + '/' + id + '/print');

const payInvoice = (id, paid) => httpClient.put(END_POINT + '/' + id + '/paid', { paid })

const addTaxes = (id) => httpClient.post(END_POINT + '/' + id + '/tax');

const removeTaxes = (id) => httpClient.post(END_POINT + '/' + id + '/taxfree');

const getFiscalYears = () => httpClient.get(END_POINT + '/years');

export {
    getAllInvoices,
    getInvoice,
    generateInvoice,
    createInvoice,
    updateInvoice,
    deleteInvoice,
    cancelInvoice,
    lossInvoice,
    payInvoice,
    addTaxes,
    removeTaxes,
    getFiscalYears
}

