<template>
  <div>
      <b-nav pills align="right" class="mr-2">
            <b-form-checkbox-group
            v-model="statusSelected"
            :options="statusOptions"
            name="statusCheckbox"
            @change="filterItems"
            buttons
            button-variant="primary"
            size="sm"
            class="checkbox"
          >
        </b-form-checkbox-group>
      </b-nav>
  <BMSTable
    :items="filterItems()"
    :fields="fields"
    :sort-compare="defaultSort"
    sort-by="invoice_number"
    :sort-desc='true'
    hover
    focus
    selectable
    :urlBuilder="buildUrl"
    >
    <template #cell(client_project)="row">
        {{ row.item.project.client.company }} - {{ row.item.project.name }}
    </template>

    <template #cell(actions)="row">
      <b-button-group>
        <b-button :to="{ name: 'Invoice', params: { id: row.item.id }}" variant="primary">
          <b-icon icon="pencil" />
        </b-button>
        <b-button @click="openPDF(row.item.id)" variant="primary" v-if="row.item.status != 'PENDING'">
          <b-icon icon="download" />
        </b-button>
      </b-button-group>
    </template>

    <template #cell(age)="row">
      <span :class="getAgeColor(getAge(row.item))" v-if="row.item.status != 'CANCELLED' && row.item.status != 'LOSS'">{{ getAge(row.item) }} days</span>
    </template>

    <template v-for="column in ['taxes_total', 'subtotal', 'total']" v-slot:[`cell(${column})`]="data">
      ${{ data.value.toFixed(2) }}
    </template>

  </BMSTable>
  
  </div>
</template>

<script>

import BMSTable from '@/components/BMSTable';
import { generateInvoice } from '@/api/invoices.api'
import crudMixin from '@/api/crud'


export default {
    mixins: [crudMixin],
    props: {
        items: Array
    },
  data() {
    return {
      fields: [{
        key: 'invoice_number',
        label: 'Invoice #',
        sortable: true,
      }, {
        key: 'client_project',
        label: 'Client - Project',
        sortable: true,
       }, {
        key: 'invoiced',
        label: 'Invoiced',
        sortable: true,
      }, {
        key: 'subtotal',
        label: 'Sub-Total',
        sortable: true,
        formatter: this.getSubtotal,
        sortByFormatted: true,
        footer: this.moneyTotal
      }, {
        key: 'taxes_total',
        label: 'Taxes',
        sortable: true,
        formatter: this.getTaxes,
        sortByFormatted: true,
        footer: this.moneyTotal
      }, {
        key: 'total',
        label: 'Total',
        sortable: true,
        formatter: this.getTotal,
        sortByFormatted: true,
        footer: this.moneyTotal
      }, {
        key: 'currency',
        label: 'Currency',
        sortable: true,
      }, {
        key: 'age',
        label: 'Age',
        sortable: true,
        //formatter: this.getAge,
        sortByFormatted: true
      }, {
        key: 'paid',
        label: 'Paid',
        sortable: true,
      }, {
        key: 'status',
        label: 'Status',
        sortable: true,
      }, {
        label: 'Action',
        key: 'actions'
      }],
      filter: null,
      statusSelected: ["PENDING", "INVOICED", "PAID"],
      statusOptions: [
        { text: "LOSS", value: "LOSS" },
        { text: "CANCELLED", value: "CANCELLED" },
        { text: "PENDING", value: "PENDING" },
        { text: "INVOICED", value: "INVOICED" },
        { text: "PAID", value: "PAID" }
      ]
    }
  },
  methods: {
    filterItems(){
      if(this.statusSelected.length == 0){
        return this.items
      }
      else {
        return this.items.filter(o => this.statusSelected.includes(o.status))
      }
      
    },
    openPDF(id) {
        this.call(
            generateInvoice(id),
            (res) => {
                window.open(res.data.url, "_blank");
            }
        )
    },
    defaultSort(aRow, bRow, key) {
        if(key != 'invoice_number') {
            return null
        } else {
            if(aRow.invoice_number == null) {
                return 1
            }  else if (bRow.invoice_number == null) {
                return -1
            }
            return aRow.invoice_number - bRow.invoice_number;
        }
    },
    getSubtotal(value) {
        return value * 1.0
    },
    getTaxes(value) {
        return value * 1.0
    },
    getTotal(value, key, invoice) {
      return this.getSubtotal(invoice.subtotal) + this.getTaxes(invoice.taxes_total);
    },
    getAgeColor(age) {
      let color = 'light'
      if (age >= 60) {
        color = 'danger';
      } else if (age >= 30) {
        color = 'warning';
      }
      return `badge badge-${color}`
    },
    getAge(invoice) {
      let age = 0;
      if (invoice.invoiced) {
        age = new Date(invoice.invoiced);

        let paid = (invoice.paid)? new Date(invoice.paid) : new Date();
        age = Math.floor((paid.getTime() - age.getTime())/(1000*60*60*24));
      }
      return age
    },
    buildUrl(invoice){
      return { name: 'Invoice', params: { id: invoice.id }}
    },
    moneyTotal(field, items) {
        let key = field.key
        let formatter = field.formatter
        if(!(formatter instanceof Function)) {
            return ""
        }
        let to_return = {}
        items.forEach(item => {
            if(!(item.currency in to_return)) {
                to_return[item.currency] = 0
            }
            to_return[item.currency] += parseFloat(formatter(item[key], key, item))
        })
        return Object.keys(to_return).map(function(x){return x+":$"+to_return[x].toFixed(2);}).join('\n');
    }
  },
  components: {
      BMSTable
  }
}

</script>
<style>
.btn-primary.active{
    color: #fff !important;
    background-color: #4F2B76 !important;
    border-color: #4F2B76 !important;
}
.btn-primary{
    color: #4F2B76 !important;
    background-color: #fff !important;
    border-color: #4F2B76 !important;
}
</style>
