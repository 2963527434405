<template>
<div>
    <!-- Breadcrumb start -->
      <ol class="breadcrumb">
          <li class="breadcrumb-item">Invoices</li>
      </ol>
      <!-- Breadcrumb end -->

      <!-- App actions start -->
      <b-button
        to="invoices/new"
        class="btn btn-success float-left">
        <b-icon icon="plus-circle" />
        Create Invoice
      </b-button>

<!--      <h5 class="align-center"> FY ends: {{ financialYearStart }} </h5> -->
      <div class="d-flex flex-wrap justify-content-between align-items-start">
        <b-nav pills align="left">
          <b-dropdown variant="primary" :text="'FY' + selectedYear" class="ml-3">
          <b-dropdown-item 
            v-for="index in (fyEnd - fyStart + 1)" 
            :key="index" 
            @click="getFiscalYear(fyStart + index - 1 )"
            :active="selectedYear == ( fyStart + index - 1 )">
            FY{{ fyStart + index - 1 }}
          </b-dropdown-item>
      </b-dropdown>
      </b-nav>
      </div>
      <InvoiceTable :items="items"/>
    </div>
</template>

<script>

import { getSetting } from '@/api/settings.api'
import { getAllInvoices, getFiscalYears } from '@/api/invoices.api'
import InvoiceTable from '@/components/InvoiceTable';
import crudMixin from '@/api/crud'

export default {
    mixins: [crudMixin],
  data() {
    return {
      items: [],
      currentYear: null,
      selectedYear:null,
      fyStart: null,
      fyEnd: null,
      fyDate: null,
    }
  },
  mounted() {
    this.currentYear = new Date().getFullYear() + 1;
    this.getAllYears();
    //this.getFiscalYear(this.selectedYear);
    this.getFinancialYear();
  },
  computed: {
    financialYearStart: function() {
        if(this.fyDate == null) {
            return ''
        }
        return this.fyDate.toLocaleString('default', { month: 'long' }) + " " + this.fyDate.getDate();
    }
  },
  methods: {
    getAllYears() {
        this.call(
            getFiscalYears(),
            (res) => {
                this.fyStart = res.data.years[0]
                this.fyEnd = res.data.years[1]
                this.selectedYear = this.fyEnd
                this.getFiscalYear(this.selectedYear)
            }
        )
    },
    getFinancialYear() {
        this.call(
            getSetting('fiscal_date'),
            (res) => {
                this.fyDate = new Date(res.data.setting.value)
            }
        )
    },
    getAgeColor(age) {
      let color = 'light'
      if (age >= 60) {
        color = 'danger';
      } else if (age >= 30) {
        color = 'warning';
      }
      return `badge badge-${color}`
    },
    getAge(invoice) {
      let age = 0;
      if (invoice.invoiced) {
        age = new Date(invoice.invoiced);

        let paid = (invoice.paid)? new Date(invoice.paid) : new Date();
        age = Math.floor((paid.getTime() - age.getTime())/(1000*60*60*24));
      }
      return age
    },
    getFiscalYear(year) {
        this.selectedYear = year
        this.call(
            getAllInvoices(new URLSearchParams([
                ['fiscal_year', year]]
            )),
            (res) => {
                this.items = res.data.invoices
            }
        )
    }
  },
  components: {
      InvoiceTable
  }
}

</script>